/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

*, *:before, *:after {
  box-sizing: inherit; }

* {
  outline: none; }

html {
  font-size: 16px;
  box-sizing: border-box;
  background-color: #00703c;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: none; }

body {
  font-family: "Open Sans", sans-serif;
  color: #333333; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Rokkitt", serif;
  margin: 0; }

p {
  margin: 0; }

a {
  color: #3498db;
  text-decoration: none;
  transition: all 125ms ease-in-out; }
  a:hover {
    color: #217dbb; }

section > .section-inner {
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  padding: 0 1rem; }

body {
  padding-bottom: 3.75rem; }
  @media only screen and (min-width: 37.5625em) {
    body {
      padding-bottom: 0; } }

.top-bar {
  position: fixed;
  z-index: 2;
  width: 100%;
  left: 0;
  bottom: 0;
  color: #ffffff; }
  @media only screen and (min-width: 37.5625em) {
    .top-bar {
      position: relative; } }

.top-bar .section-inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  border-top: 0.0625rem solid #74808a; }
  @media only screen and (min-width: 37.5625em) {
    .top-bar .section-inner {
      display: flex;
      justify-content: flex-end;
      border-color: transparent; } }

.top-bar a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
  width: 100%;
  color: #ffffff;
  cursor: pointer;
  background-color: #00572e; }
  .top-bar a:not(:last-of-type) {
    border-right: 0.0625rem solid #74808a; }
  @media only screen and (min-width: 37.5625em) {
    .top-bar a:hover {
      border: 0.125rem solid #00572e;
      background-color: #004c29; } }
  @media only screen and (min-width: 37.5625em) {
    .top-bar a {
      height: 2.5rem;
      width: 12.5rem;
      margin: 0.625rem;
      border: 0.125rem solid #c4941d;
      border-radius: 0.5rem;
      background-color: goldenrod; } }

.top-bar i {
  margin-right: 0.5rem; }

.top-bar span {
  display: none;
  align-self: center;
  font-size: 1.5rem;
  margin-right: 0.625rem; }
  @media only screen and (min-width: 37.5625em) {
    .top-bar span {
      display: block; } }

.hero {
  background-color: #004c29; }

.hero .section-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 14.5rem;
  position: relative;
  background-image: url(../img/hero-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  @media only screen and (min-width: 37.5625em) {
    .hero .section-inner {
      height: 30rem; } }

.hero .section-inner:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4); }

.hero img {
  width: 2.5625rem;
  filter: drop-shadow(0.125rem 0.25rem 0.1875rem rgba(0, 0, 0, 0.3));
  z-index: 1; }
  @media only screen and (min-width: 37.5625em) {
    .hero img {
      width: 5.625rem; } }

.hero h1,
.hero h2,
.hero h3 {
  color: #ffffff;
  line-height: 1.2;
  text-shadow: 0.125rem 0.25rem 0.1875rem rgba(0, 0, 0, 0.3);
  z-index: 1; }

.hero h1 {
  font-size: 3rem;
  font-weight: normal; }
  @media only screen and (min-width: 37.5625em) {
    .hero h1 {
      font-size: 5.125rem; } }

.hero h2 {
  font-size: 1.5rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin-bottom: 1.5rem; }
  @media only screen and (min-width: 37.5625em) {
    .hero h2 {
      font-size: 2.375rem;
      margin-bottom: 3rem; } }

.hero h3 {
  font-size: 1.125rem;
  font-family: "Open Sans", sans-serif;
  font-weight: bold; }
  @media only screen and (min-width: 37.5625em) {
    .hero h3 {
      font-size: 1.75rem; } }

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.75rem; }

.banner span {
  font-size: 1.5rem;
  line-height: 1.2;
  color: #ffffff; }

.text {
  background-color: #004c29; }

.text .section-inner {
  padding: 2rem 1rem; }

.text p {
  color: #ffffff;
  font-size: 1.125rem;
  text-align: center;
  margin: 0 auto; }
  .text p:not(:last-of-type) {
    margin-bottom: 1rem; }
  @media only screen and (min-width: 37.5625em) {
    .text p {
      width: 43.75rem; } }

.contact {
  background-color: #74808a; }

.contact h2 {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  line-height: 3.75rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1rem; }

.contact .myform {
  display: flex;
  justify-content: center; }

.contact .myform form {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  width: 100%; }

.contact .myform div {
  position: relative; }

.contact input {
  width: 100%;
  height: 3.125rem;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  line-height: 3.125rem;
  color: #333333;
  padding: 0 0.5rem;
  margin-top: 1.25rem; }

.contact label {
  margin-bottom: 0.25rem;
  padding-left: 0.5rem;
  transition: all 125ms ease-in-out; }

.contact input + label {
  position: absolute;
  top: 0;
  opacity: 0; }

.contact input:not(:placeholder-shown) + label {
  opacity: 1; }

.contact .submit {
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  margin-bottom: 3.75rem; }
  @media only screen and (min-width: 64.063em) {
    .contact .submit {
      grid-column: span 2;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center; } }

.contact .submit span {
  color: #770000;
  font-size: 0.75rem;
  margin-left: 0.5rem;
  margin-bottom: 0.25rem; }
  @media only screen and (min-width: 64.063em) {
    .contact .submit span {
      margin-right: 2rem; } }

.contact button {
  height: 3.125rem;
  width: 100%;
  border: none;
  border-radius: 0.5rem;
  background-color: goldenrod;
  cursor: pointer; }
  @media only screen and (min-width: 64.063em) {
    .contact button {
      width: 12.5rem; } }

.contact .form-grid {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%; }
  @media only screen and (min-width: 64.063em) {
    .contact .form-grid {
      width: 56.25rem;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1.25rem; } }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7); }

.overlay {
  display: flex;
  align-items: center;
  justify-content: center; }

.overlay .overlay-inner {
  width: 90%;
  max-height: 80%;
  background-color: #ffffff;
  padding: 2rem 1rem;
  text-align: center;
  border-radius: 1rem; }
  @media only screen and (min-width: 37.5625em) {
    .overlay .overlay-inner {
      max-width: 31.25rem; } }

.overlay h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #00703c; }

.overlay p {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-bottom: 2rem; }

.overlay a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem; }

.overlay i {
  margin-right: 0.5rem; }
