// config-colors.scss      ~/styles/
/////////////////////////////////////////////////////////////////////

$black: #000000;
$fun-green: #00703c;
$jungle: #1e1e1e;
$mine: #333333;
$bleu: #3498db;
$raven: #74808a;
$tussoc: #c09b4b;
$mercury :#e6e6e6;
$white: #ffffff;

$jap-maple: #770000;



$warn: $jap-maple;